export { };
export type Lang = 'fa' | 'en' | 'ar';
export type CultureName = 'fa-ir' | 'en-us' | 'ar-sa';
export type Culture = { name: Lang, culture: CultureName, title: string, rtl: boolean };

declare global {
    const I18n: {
        cultures: Array<Culture>,
        getCurrentCulture: () => Culture,
        translates: { [key: string]: string },
        enumTranslates: { [key: string]: string },
        getCurrentUrl: (lang: Culture) => string
    };
}

const win: any = window,
    i18n: {
        cultures: Array<Culture>,
        getCurrent: () => Culture,
        translates: { [key: string]: string },
        enumTranslates: { [key: string]: string }
    } = win.$i18n;


win.I18n = {
    translates: i18n.translates,
    enumTranslates: i18n.enumTranslates,
    cultures: i18n.cultures,
    getCurrentCulture: i18n.getCurrent,
    getCurrentUrl: (lang: Culture) => {
        let url = new URL(window.location.href);
        return `${url.origin}?lang=${lang.name}${url.hash}`;
    }
}