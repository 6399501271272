import { createAsyncThunk } from "@reduxjs/toolkit";
import { templateTypes as types } from "@app/types";
import { ApiClient } from "./___ApiClient";
import { StoreManager, helpers, SessionManager } from "@app/core";
import { TApiInput } from '@dariosoft/tools'

export type TTemplateApiParam = { clientId: string }

export class TemplateApi extends ApiClient {
    private constructor() {
        super('admin/api/v1/template')
    }

    //#region PUBLICS
    public static get instance(): TemplateApi {
        if (TemplateApi._instance == null) TemplateApi._instance = new TemplateApi();
        return TemplateApi._instance!;
    }

    public getPreviewUrl = (templateId: string): string => { 
        let token = SessionManager.instance.getToken();
        return `${helpers.appSettings.apiUrl}/admin/api/v1/template/preview/${templateId}?t=Bearer ${token}`;
    }

    public create = {
        thunk: createAsyncThunk('admin/template/create', (payload: TApiInput<types.TTemplateInfo, TTemplateApiParam>) => this.api.create(payload.data)),
        submit: (payload: TApiInput<types.TTemplateInfo, TTemplateApiParam>) => StoreManager.dispatch(this.create.thunk(payload) as any)
    }
    public update = {
        thunk: createAsyncThunk('admin/template/update', (payload: TApiInput<types.TTemplateInfo, TTemplateApiParam>) => this.api.update(payload.data)),
        submit: (payload: TApiInput<types.TTemplateInfo, TTemplateApiParam>) => StoreManager.dispatch(this.update.thunk(payload) as any)
    }
    public delete = {
        thunk: createAsyncThunk('admin/template/delete', (payload: TApiInput<types.TTemplateModel, TTemplateApiParam>) => this.api.delete(payload.data.id)),
        submit: (payload: TApiInput<types.TTemplateModel, TTemplateApiParam>) => StoreManager.dispatch(this.delete.thunk(payload) as any)
    }
    public get = {
        thunk: createAsyncThunk('admin/template/get', (id: string) => this.api.get(id)),
        submit: (id: string) => StoreManager.dispatch(this.get.thunk(id) as any)
    }

    public getContext = {
        thunk: createAsyncThunk('admin/template/get/context', (id: string) => this.api.getContext(id)),
        submit: (id: string) => StoreManager.dispatch(this.getContext.thunk(id) as any)
    }
    public setContext = {
        thunk: createAsyncThunk('admin/template/set/context', (payload: types.TTemplateContext) => this.api.setContext(payload)),
        submit: (payload: types.TTemplateContext) => StoreManager.dispatch(this.setContext.thunk(payload) as any)
    }
    public mailTo = {
        thunk: createAsyncThunk('admin/template/mailTo', (payload: types.TTemplateMailTo) => this.api.mailTo(payload)),
        submit: (payload: types.TTemplateMailTo) => StoreManager.dispatch(this.mailTo.thunk(payload) as any)
    }
    public list = {
        thunk: createAsyncThunk('admin/template/list', (payload: TApiInput<TListLoadModel, TTemplateApiParam>) => this.api.list(payload.params.clientId, payload.data)),
        submit: (payload: TApiInput<TListLoadModel, TTemplateApiParam>) => StoreManager.dispatch(this.list.thunk(payload) as any)
    }
    //#endregion

    //#region PRIVATES
    private static _instance: TemplateApi | null = null;
    private readonly api = {
        create: (payload: types.TTemplateInfo): Promise<IResult<TSubmitResultDto>> => this.http.postJson(`create`, payload),
        update: (payload: types.TTemplateInfo): Promise<IResult> => this.http.postJson(`update`, payload),
        delete: (id: string): Promise<IResult> => this.http.delete(`delete/${id}`),
        get: (id: string): Promise<IResult<types.TTemplateModel>> => this.http.get(`get/${id}/by-id`),
        getContext: (id: string): Promise<IResult<types.TTemplateContext>> => this.http.get(`get/${id}/context`),
        setContext: (payload: types.TTemplateContext): Promise<IResult> => this.http.postJson(`set/context`, payload),
        mailTo: (payload: types.TTemplateMailTo): Promise<IResult> => this.http.postJson(`mailto`, payload),
        list: (clientId: string, model: TListLoadModel): Promise<IListResult<types.TTemplateModel>> => this.http.get<IListResult<types.TTemplateModel>>(`list/${clientId}?${super.getQueryString(model)}`),
    }
    //#endregion
}